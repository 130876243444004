import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Alert,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import { Select as SelectMUI } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CreatableSelect from "react-select/creatable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ModalCadastroCliente from "app/components/ModalCadastroCliente/ModalCadastroCliente";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CurrencyInput from "react-currency-input-field";
import moment from "moment";
import { PermissionContext } from "app/contexts/PermissionContext";
import { v4 as uuidv4 } from "uuid";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchemaEdit = yup.object().shape({
  descricao: yup.string().nullable(),
  situacao: yup.string().required("Campo obrigatório"),
  clienteId: yup.number().required("Campo obrigatório"),
  tipocontratocontrato: yup
    .array()
    .required("Campo obrigatório")
    .min(1, "Selecione ao menos 1 tipo de contrato"),
  elaboradores_orcamento_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 elaborador"),
  orientadores_contrato_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 orientador"),
  status_orcamento_id: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório"),
  unidades_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 unidade"),
  tipoOrcamentoId: yup.number().required("Campo obrigatório"),
  situacao: yup.string().required("Campo obrigatório"),
  dataVencimento: yup.date().optional().typeError("Campo obrigatório"),
  prazoRenovacao: yup.date().optional().typeError("Campo obrigatório"),
});

const validationSchema = yup.object().shape({
  descricao: yup.string().nullable(),
  situacao: yup.string().required("Campo obrigatório"),
  clienteId: yup.number().required("Campo obrigatório"),
  tipocontratocontrato: yup
    .array()
    .required("Campo obrigatório")
    .min(1, "Selecione ao menos 1 tipo de contrato"),
  elaboradores_orcamento_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 elaborador"),
  orientadores_contrato_id: yup
    .array()
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 orientador"),
  unidades_id: yup
    .array("")
    .of(yup.number())
    .required("Campo obrigatório")
    .min(1, "Obrigatório no mínimo 1 unidade"),
  status_orcamento_id: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório"),
  tipoOrcamentoId: yup.number().required("Campo obrigatório"),
  dataVencimento: yup.date().optional().typeError("Data inválida"),
  prazoRenovacao: yup.date().optional().typeError("Data inválida"),
  data_aprovacao: yup
    .date()
    .nullable()
    .typeError("Data inválida")
    .when("status_orcamento_id", {
      is: (status) => status === 1,
      then: yup.date().optional().typeError("Data inválida"),
      otherwise: yup.date().nullable(),
    }),
});

const ContratoForm = () => {
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));
  const initialValues = {
    descricao: "",
    clienteId: "",
    elaboradores_orcamento_id: [],
    orientadores_contrato_id: [],
    unidades_id: [],
    status_orcamento_id: 5, // O status padrão informado pela Ambiental é 'Aguardando Resposta'
    tipoOrcamentoId: "",
    situacao: "padrao",
    tipocontratocontrato: [],
    dataVencimento: "",
    prazoRenovacao: "",
    colaborador_que_criou_id: Number(userId),
    data_aprovacao: "",
  };

  const { id } = useParams();
  const { theme } = useJumboTheme();
  const formRef = useRef();
  const colaboradorId = Number(localStorage.getItem("id"));
  const [contrato, setContrato] = useState(initialValues);
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const [centroDeCustos, setCentroDeCustos] = useState([]);
  const [tiposServicos, setTiposServicos] = useState([]);
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [statusOrcamentos, setStatusOrcamentos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [tipoOrcamentos, setTiposOrcamentos] = useState([]);
  const [tiposContrato, setTiposContrato] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [setores, setSetores] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [isContratoFixo, setIsContratoFixo] = useState(false);
  const [
    valoresFinaisServicosCadastrados,
    setValoresFinaisServicosCadastrados,
  ] = useState([]);
  const [
    valoresFinaisServicosNaoCadastrados,
    setValoresFinaisNaoServicosCadastrados,
  ] = useState([]);
  const [clienteIdSelecionado, setClienteIdSelecionado] = useState(null);
  const [modalAberto, setModalAberto] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const Swal = useSwalWrapper();
  const location = useLocation();

  const [novoNomeCliente, setNovoNomeCliente] = useState("");
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const handleOpenModal = () => {
    setModalAberto(true);
  };

  const handleCloseModal = (idClienteCriado) => {
    setContrato((prevContrato) => ({
      ...prevContrato,
      clienteId: idClienteCriado,
    }));
    setModalAberto(false);
  };

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getContrato = useCallback(async () => {
    try {
      await ApiService.get(`/contratos/${id}`).then((response) => {
        if (response.status === 200) {
          const {
            Cliente,
            descricao,
            dataVencimento,
            prazoRenovacao,
            situacao,
            tipocontratocontrato,
            elaboradores_orcamento,
            orientadores,
            unidades,
            tipo_orcamento,
            colaborador_que_criou,
            status_orcamentario,
            data_aprovacao,
          } = response.data;

          const clienteId = Cliente?.id;
          const colaboradorId = colaborador_que_criou?.id;
          const elaboradoresOrcamentoId = elaboradores_orcamento?.map(
            (elaborador) => elaborador?.usuario?.id
          );
          const orientadoresId = orientadores?.map(
            (orientador) => orientador?.usuario?.id
          );
          const unidadesId = unidades?.map((unidade) => unidade.id);
          const tipoOrcamentoId = tipo_orcamento?.id;

          let contratoAtualizacao = {
            descricao,
            clienteId: Number(clienteId),
            elaboradores_orcamento_id: elaboradoresOrcamentoId,
            orientadores_contrato_id: orientadoresId,
            unidades_id: unidadesId,
            status_orcamento_id: status_orcamentario?.id,
            tipoOrcamentoId,
            situacao,
            tipocontratocontrato,
            dataVencimento: dataVencimento
              ? moment
                  .parseZone(response?.data?.dataVencimento)
                  .format("yyyy-MM-DD")
              : "",
            prazoRenovacao: prazoRenovacao
              ? moment
                  .parseZone(response?.data?.prazoRenovacao)
                  .format("yyyy-MM-DD")
              : "",
            colaborador_que_criou_id: Number(colaboradorId),
            data_aprovacao: data_aprovacao
              ? moment.parseZone(data_aprovacao).format("yyyy-MM-DD")
              : null,
          };

          setClienteIdSelecionado(Cliente?.id);

          if (
            response.data?.tipocontratocontrato?.some(
              (tipoContrato) =>
                tipoContrato.descricao === "CONTRATO FIXO" ||
                (tipoContrato.tipos_contratos &&
                  tipoContrato.tipos_contratos.descricao === "CONTRATO FIXO")
            )
          ) {
            setIsContratoFixo(true);
          }
          if (
            response.data?.tipocontratocontrato?.some(
              (tipoContrato) =>
                tipoContrato.descricao === "RENOVAÇÃO" ||
                (tipoContrato.tipos_contratos &&
                  tipoContrato.tipos_contratos.descricao === "RENOVAÇÃO")
            )
          ) {
            contratoAtualizacao = {
              ...contratoAtualizacao,
              dataVencimento: dataVencimento
                ? moment
                    .parseZone(response?.data?.dataVencimento)
                    .format("yyyy-MM-DD")
                : "",
              prazoRenovacao: prazoRenovacao
                ? moment
                    .parseZone(response?.data?.prazoRenovacao)
                    .format("yyyy-MM-DD")
                : "",
            };
          }
          setContrato(contratoAtualizacao);
        }
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getCentroDeCustos = useCallback(async () => {
    try {
      await ApiService.get("/centrosdecustos/all").then((response) => {
        setCentroDeCustos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposServicos = useCallback(async () => {
    try {
      await ApiService.get("/tiposservico").then((response) => {
        setTiposServicos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getProcessosAdministrativos = useCallback(async () => {
    try {
      await ApiService.get("/processosadmin/all").then((response) => {
        setProcessosAdministrativos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTarefas = useCallback(async () => {
    try {
      await ApiService.get("/tarefas/all").then((response) => {
        setTarefas(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getStatusOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        if (response.status === 200) {
          const status = response.data?.filter(
            (statusOrcamento) =>
              String(statusOrcamento?.descricao).toLowerCase() !==
              "finalizado completo"
          );
          setStatusOrcamentos(status);
        }
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getColaboradores = useCallback(async () => {
    try {
      await ApiService.get(
        "/users/colaboradores?filtroRoles=financeiro_admin"
      ).then((response) => {
        setColaboradores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/tipoorcamento").then((response) => {
        setTiposOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposContrato = useCallback(async () => {
    try {
      await ApiService.get("/tipocontrato").then((response) => {
        setTiposContrato(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getClientes = useCallback(async () => {
    try {
      await ApiService.get("/clientes/all").then((response) => {
        setClientes(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getUnidades = async () => {
    try {
      await ApiService.get(
        `/unidade/cliente/select/${clienteIdSelecionado}`
      ).then((response) => {
        if (response.status === 200) {
          setUnidades(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSetores = useCallback(async () => {
    try {
      await ApiService.get("/setores").then((response) => {
        setSetores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    if (id) {
      const newValues = {
        ...values,
        colaborador_que_criou_id: colaboradorId,
      };
      if (
        values.tipocontratocontrato?.every(
          (tipoContrato) =>
            tipoContrato.descricao !== "RENOVAÇÃO" &&
            (!tipoContrato.tipos_contratos ||
              tipoContrato.tipos_contratos.descricao !== "RENOVAÇÃO")
        )
      ) {
        delete values.dataVencimento;
        delete values.dataRenovacao;
      }

      delete newValues.Cliente;
      delete newValues.cliente_id;
      delete newValues.tipo_orcamento;
      delete newValues.elaborador_orcamento;
      delete newValues.Imovel;
      delete newValues.id;
      delete newValues.createdAt;
      delete newValues.updatedAt;
      delete newValues.deletedAt;


      try {
        await ApiService.put(`/contratos/${id}`, newValues).then((response) => {
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso!");
            navigate("/app/listar-contratos");
          }
        });
      } catch (error) {
        console.log(error);
        toast("error", error.response?.data?.message);
      }
    } else {
      let newValues = {
        ...values,
        colaborador_que_criou_id: colaboradorId,
      };
      if (
        !id &&
        values?.tipocontratocontrato?.some((tipoContrato) =>
          String(tipoContrato?.descricao).toLowerCase().includes("fixo")
        )
      ) {
        newValues = {
          ...values,
          status_orcamento_id: 1,
        };
      }
      try {
        await ApiService.post("/contratos", newValues)
          .then((response) => {
            if (response.status === 201) {
              toast("success", "Criado com sucesso");
              navigate("/app/listar-contratos");
              resetForm();
            }
          })
          .catch((error) => {
            console.log(error);

            toast("error", error?.response?.data?.message);

            if (error.response.data) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      } catch (error) {
        console.log(error);
        toast("error", "Ocorreu um erro");
      }
    }
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteServico(id);
      }
    });
  };

  const handleDeleteServico = useCallback(async (id) => {
    try {
      ApiService.delete(`/servicos/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso!");
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // Criação de novos itens no CreateSelect

  const handleCreateCliente = (value) => {
    setNovoNomeCliente(value);
  };

  useEffect(() => {
    if (novoNomeCliente !== "") {
      handleOpenModal();
    }
  }, [novoNomeCliente]);

  const handleCreateTipoOrcamento = useCallback(async (descricao) => {
    const response = await ApiService.post("/tipoorcamento", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getTiposOrcamento();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const modalAlertDeleteContrato = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o contrato?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteContrato(id);
      }
    });
  };

  const deleteContrato = useCallback(async (id) => {
    try {
      ApiService.delete(`/contratos/${id}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso");
            navigate(`/app/listar-contratos`);
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        console.log(`TEM ID`);
        getContrato();
      }
      await Promise.all([
        getCentroDeCustos(),
        getTiposServicos(),
        getProcessosAdministrativos(),
        getTarefas(),
        getStatusOrcamento(),
        getColaboradores(),
        getClientes(),
        getTiposContrato(),
        getTiposOrcamento(),
        getSetores(),
      ]);
      setDadosCarregados(true);
    };
    fetchData();
    if (location && String(location.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [id]);

  useEffect(() => {
    if (clienteIdSelecionado) {
      getUnidades();
    }
  }, [clienteIdSelecionado]);

  useEffect(() => {
    if (dadosCarregados && contrato) {
      const temPermissaoDeAcessarAPagina = hasPermission("Financeiro", "read");
      const contratoCriadoPeloUsuarioAtual =
        contrato?.colaborador_que_criou_id === userId;
      if (!temPermissaoDeAcessarAPagina && !contratoCriadoPeloUsuarioAtual) {
        navigate("/app");
      }
    }
  }, [dadosCarregados, contrato]);

  return (
    <>
      {dadosCarregados && (
        <JumboContentLayout
          header={
            <HeaderBreadcrumbs
              id={id}
              title={"Contratos"}
              subtitle={
                isDeleteMode
                  ? "Deletar contrato"
                  : id
                  ? "Editar contrato"
                  : "Cadastro de contrato"
              }
              titleUrl={"/app/listar-contratos"}
            />
          }
          layoutOptions={layoutOptions}
        >
          {lg && <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }} />}
          {isDeleteMode && (
            <Stack width={"100%"} p={1}>
              <Alert severity="error">
                Você está prestes a excluir o contrato abaixo:
              </Alert>
            </Stack>
          )}

          <Paper sx={{ p: "15px" }}>
            <Formik
              innerRef={formRef}
              initialValues={contrato}
              validationSchema={id ? validationSchemaEdit : validationSchema}
              enableReinitialize
              validateOnChange
              validanteOnBlur
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleBlur,
                isSubmitting,
                handleChange,
                setFieldValue,
                errors,
              }) => (
                <Form style={{ width: "100%" }} noValidate autoComplete="off">
                  <Grid container alignContent={"center"} spacing={2}>
                    <Grid item lg={4}>
                      <Typography my={1}>Código do Contrato</Typography>
                      <TextField
                        fullWidth
                        name="descricao"
                        // label={
                        //   values.situacao !== "aguardando_analise"
                        //     ? "Será gerado automaticamente"
                        //     : "Código"
                        // }
                        label={"Código"}
                        required
                        value={values.descricao ?? null}
                        onChange={handleChange}
                        disabled={isDeleteMode}
                        sx={{ background: "#ffffff" }}
                        helperText={
                          "Se deixado em branco, o código será gerado automaticamente"
                        }
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Typography my={1}>Cliente</Typography>
                      {dadosCarregados && (
                        <CreatableSelect
                          isClearable
                          styles={customStyles}
                          name="clienteId"
                          isDisabled={isDeleteMode}
                          onChange={(cliente) => {
                            setFieldValue(
                              "clienteId",
                              cliente && cliente.id ? cliente.id : null
                            );
                            setClienteIdSelecionado(cliente?.id);
                          }}
                          onCreateOption={(value) => {
                            handleCreateCliente(value);
                          }}
                          options={clientes}
                          getOptionLabel={(option) =>
                            option.__isNew__ ? option.label : option.nome
                          }
                          value={
                            clientes &&
                            (clienteIdSelecionado !== null
                              ? clientes.find(
                                  (cliente) =>
                                    cliente.id === clienteIdSelecionado
                                )
                              : clientes.find(
                                  (cliente) => cliente.id === values.clienteId
                                ))
                          }
                          placeholder={"Selecione um Cliente"}
                        />
                      )}
                      <ModalCadastroCliente
                        nomeCliente={novoNomeCliente}
                        isOpen={modalAberto}
                        onClose={handleCloseModal}
                        getClientes={getClientes}
                        setNomeCliente={setNovoNomeCliente}
                        atualizarImoveis={getUnidades}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="clienteId"
                        style={{ color: "red" }}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Typography my={1}>Unidades</Typography>
                      {dadosCarregados && (
                        <Select
                          styles={customStyles}
                          options={unidades}
                          name="unidades_id"
                          required
                          isMulti={true}
                          isDisabled={isDeleteMode}
                          getOptionLabel={(option) => option.nomeUnidade}
                          getOptionValue={(option) => option.id}
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "unidades_id",
                              selectedOptions
                                ? selectedOptions.map((option) => option.id)
                                : []
                            );
                          }}
                          placeholder="Selecione as unidades"
                          value={unidades.filter((unidade) =>
                            values.unidades_id?.includes(unidade.id)
                          )}
                        />
                      )}
                      <ErrorMessage
                        component={"div"}
                        name="unidades_id"
                        style={{ color: "red" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignContent={"center"} spacing={2}>
                    <Grid item lg={4}>
                      <Typography my={1}>Elaborador do orçamento</Typography>
                      {dadosCarregados && (
                        <Select
                          styles={customStyles}
                          options={colaboradores}
                          name="elaboradores_orcamento_id"
                          required
                          isMulti={true}
                          isDisabled={isDeleteMode}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "elaboradores_orcamento_id",
                              selectedOptions
                                ? selectedOptions.map((option) => option.id)
                                : []
                            );
                          }}
                          placeholder="Selecione os elaboradores do orçamento"
                          value={colaboradores?.filter((colaborador) =>
                            values?.elaboradores_orcamento_id?.includes(
                              colaborador.id
                            )
                          )}
                        />
                      )}
                      <ErrorMessage
                        component={"div"}
                        name="elaboradores_orcamento_id"
                        style={{ color: "red" }}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Typography my={1}>Orientadores</Typography>
                      {dadosCarregados && (
                        <Select
                          styles={customStyles}
                          options={colaboradores}
                          name="orientadores_contrato_id"
                          required
                          isMulti={true}
                          isDisabled={isDeleteMode}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "orientadores_contrato_id",
                              selectedOptions
                                ? selectedOptions.map((option) => option.id)
                                : []
                            );
                          }}
                          placeholder="Selecione os orientadores"
                          value={colaboradores.filter((colaborador) =>
                            values.orientadores_contrato_id?.includes(
                              colaborador.id
                            )
                          )}
                        />
                      )}
                      <ErrorMessage
                        component={"div"}
                        name="orientadores_contrato_id"
                        style={{ color: "red" }}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Typography my={1}>Tipo do orçamento</Typography>
                      {dadosCarregados && (
                        <>
                          <CreatableSelect
                            isClearable
                            styles={customStyles}
                            name="tipoOrcamentoId"
                            isDisabled={isDeleteMode}
                            onChange={(tipoOrcamento) => {
                              setFieldValue(
                                "tipoOrcamentoId",
                                tipoOrcamento ? tipoOrcamento.id : null
                              );
                            }}
                            onCreateOption={(value) => {
                              handleCreateTipoOrcamento(value);
                            }}
                            options={tipoOrcamentos}
                            getOptionLabel={(option) =>
                              option.__isNew__ ? option.label : option.descricao
                            }
                            getOptionValue={(option) => option.id}
                            value={tipoOrcamentos.find(
                              (tipoOrcamento) =>
                                tipoOrcamento.id === values.tipoOrcamentoId
                            )}
                            placeholder={"Selecione um Tipo de Orçamento"}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="tipoOrcamentoId"
                            style={{ color: "red" }}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item lg={4}>
                      <Typography my={1}>Status Orçamentário</Typography>
                      <SelectMUI
                        name="status_orcamento_id"
                        value={
                          !id &&
                          values?.tipocontratocontrato?.some((tipoContrato) =>
                            String(tipoContrato?.descricao)
                              .toLowerCase()
                              .includes("fixo")
                          )
                            ? 1
                            : values.status_orcamento_id
                        }
                        readOnly={isDeleteMode}
                        options={statusOrcamentos}
                        getOptionLabel={(option) => option.descricao}
                        getOptionValue={(option) => option.id}
                        onChange={handleChange}
                        styles={customStyles}
                        disabled={
                          isDeleteMode ||
                          (!id &&
                            values?.tipocontratocontrato?.some((tipoContrato) =>
                              String(tipoContrato?.descricao)
                                .toLowerCase()
                                .includes("fixo")
                            ))
                        }
                        placeholder="Selecione o Status Orçamentário"
                        fullWidth
                      >
                        {statusOrcamentos &&
                          statusOrcamentos.length > 0 &&
                          statusOrcamentos.map((status) => (
                            <MenuItem value={status.id}>
                              {status.descricao}
                            </MenuItem>
                          ))}
                      </SelectMUI>
                      <ErrorMessage
                        component={"div"}
                        name="status_orcamento_id"
                        style={{ color: "red" }}
                      />
                    </Grid>
                    {(id &&
                      [1, 3, 7, 8].includes(values.status_orcamento_id)) ||
                    values.tipocontratocontrato?.some(
                      (tipo) =>
                        String(tipo?.descricao?.toLowerCase()) ===
                        "contrato fixo"
                    ) ? (
                      <Grid item lg={4}>
                        <Typography my={1}>Data de Aprovação</Typography>
                        <TextField
                          name="data_aprovacao"
                          type="date"
                          disabled={isDeleteMode}
                          onChange={(event) => {
                            setFieldValue("data_aprovacao", event.target.value);
                          }}
                          value={values.data_aprovacao}
                          onBlur={handleBlur}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage
                          component={"div"}
                          name="data_aprovacao"
                          style={{ color: "red" }}
                        />
                      </Grid>
                    ) : null}
                    {(Array.isArray(values.tipocontratocontrato) &&
                      values.tipocontratocontrato.some(
                        (tipoContrato) => tipoContrato.descricao === "RENOVAÇÃO"
                      )) ||
                    values.tipocontratocontrato?.some(
                      (tipoContrato) =>
                        tipoContrato.tipos_contratos &&
                        tipoContrato.tipos_contratos.descricao === "RENOVAÇÃO"
                    ) ? (
                      <Grid item lg={4}>
                        <Typography my={1}>Data de Vencimento</Typography>
                        <TextField
                          name="dataVencimento"
                          type="date"
                          disabled={isDeleteMode}
                          onChange={(event) => {
                            setFieldValue("dataVencimento", event.target.value);
                          }}
                          value={values.dataVencimento}
                          onBlur={handleBlur}
                          variant="outlined"
                          fullWidth
                        />
                        <ErrorMessage
                          component={"div"}
                          name="dataVencimento"
                          style={{ color: "red" }}
                        />
                      </Grid>
                    ) : null}
                    {(Array.isArray(values.tipocontratocontrato) &&
                      values.tipocontratocontrato.some(
                        (tipoContrato) => tipoContrato.descricao === "RENOVAÇÃO"
                      )) ||
                    values.tipocontratocontrato?.some(
                      (tipoContrato) =>
                        tipoContrato.tipos_contratos &&
                        tipoContrato.tipos_contratos.descricao === "RENOVAÇÃO"
                    ) ? (
                      <Grid item lg={4}>
                        <Typography my={1}>Prazo de Renovação</Typography>
                        <TextField
                          name="prazoRenovacao"
                          type="date"
                          disabled={isDeleteMode}
                          onChange={(event) => {
                            setFieldValue("prazoRenovacao", event.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.prazoRenovacao}
                          variant="outlined"
                          fullWidth
                        />

                        <ErrorMessage
                          component={"div"}
                          name="prazoRenovacao"
                          style={{ color: "red" }}
                        />
                      </Grid>
                    ) : null}
                    {contrato.situacao === "aguardando_analise" && (
                      <Grid item lg={4}>
                        <Typography my={1}>Situação do Contrato</Typography>
                        <SelectMUI
                          name="situacao"
                          value={values.situacao}
                          readOnly={isDeleteMode}
                          onChange={handleChange}
                          styles={customStyles}
                          disabled={isDeleteMode}
                          fullWidth
                        >
                          <MenuItem value={"padrao"}>Regular</MenuItem>
                          <MenuItem value={"aguardando_analise"}>
                            Aguardando análise
                          </MenuItem>
                        </SelectMUI>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2} p={2}>
                    <Grid item lg={4}>
                      <Typography my={1}>Tipo de contrato</Typography>
                      {dadosCarregados && (
                        <FieldArray name="tipocontratocontrato">
                          {({ form, push, remove }) => (
                            <>
                              {tiposContrato.map((opcao) => {
                                const isChecked =
                                  values?.tipocontratocontrato?.some(
                                    (item) => item.tipoContratoId === opcao.id
                                  );

                                let isFixo = values.tipocontratocontrato?.some(
                                  (tipoContrato) =>
                                    tipoContrato?.descricao ===
                                      "CONTRATO FIXO" ||
                                    (tipoContrato?.tipos_contratos &&
                                      tipoContrato.tipos_contratos
                                        ?.descricao === "CONTRATO FIXO")
                                );
                                setIsContratoFixo(isFixo);

                                return (
                                  <div key={uuidv4()}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled={isDeleteMode}
                                          color="info"
                                          checked={isChecked}
                                          onChange={(e) => {
                                            const checked = e.target.checked;

                                            if (checked) {
                                              push({
                                                tipoContratoId: opcao.id,
                                                descricao: opcao.descricao,
                                              });
                                            } else {
                                              const idx =
                                                values.tipocontratocontrato.findIndex(
                                                  (item) =>
                                                    item.tipoContratoId ===
                                                    opcao.id
                                                );
                                              if (idx !== -1) {
                                                remove(idx);
                                              }
                                            }
                                          }}
                                        />
                                      }
                                      label={opcao.descricao}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </FieldArray>
                      )}
                      <ErrorMessage
                        component={"div"}
                        name="tipocontratocontrato"
                        style={{ color: "red" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignContent={"center"} spacing={2}>
                    <Grid item lg={12}>
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        marginTop={3}
                      >
                        {isDeleteMode ? (
                          <Stack direction="row" gap={2} display={"flex"}>
                            <Box>
                              <Button
                                color="error"
                                variant="contained"
                                onClick={() => {
                                  modalAlertDeleteContrato(id);
                                }}
                              >
                                Excluir Contrato
                              </Button>
                            </Box>
                            <Box>
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                  navigate(`/app/listar-contratos`);
                                }}
                              >
                                Cancelar
                              </Button>
                            </Box>
                          </Stack>
                        ) : (
                          <LoadingButton
                            loading={isSubmitting}
                            type="submit"
                            style={{ margin: "auto", display: "block" }}
                            variant="contained"
                            color="primary"
                          >
                            {id ? "Atualizar" : "Criar"}
                          </LoadingButton>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </JumboContentLayout>
      )}
    </>
  );
};

export default ContratoForm;
