import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Paid, PaidOutlined, Topic } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import ContratoHeader from "app/components/ContratoHeader/ContratoHeader";
import ModalRenegociarContrato from "app/components/ModalRenegociarContrato/ModalRenegociarContrato";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ModalPagamentoContrato from "app/components/ModalPagamentoContrato/ModalPagamentoContrato";
import ModalHistoricoPagamentoContrato from "app/components/ModalHistoricoPagamentoContrato/ModalHistoricoPagamentoContrato";
import ModalHistoricoRenegociacaoContrato from "app/components/ModalHistoricoRenegociacaoPagamento/ModalHistoricoRenegociacaoPagamento";
import { v4 } from "uuid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.array().of(
  yup.object().shape({
    servicoTarefaId: yup
      .number("Campo obrigatório")
      .required("Campo obrigatório"),
    status_orcamento_id: yup
      .number("Campo obrigatório")
      .required("Campo obrigatório"),
  })
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    minHeight: "auto",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

export const ServicoFinanceiro = () => {
  const initialValues = [];
  const userId = Number(localStorage.getItem("id"));
  const { contratoId } = useParams();
  const [contrato, setContrato] = useState([]);
  const [statusOrcamento, setStatusOrcamentos] = useState([]);
  const [statusOrcamentoSelecionado, setStatusOrcamentoSelecionado] = useState(
    []
  );
  const [isModalRenegociacaoAberto, setIsModalRenegociacaoAberto] =
    useState(false);
  const [valorPago, setValorPago] = useState(0);
  const [valorBase, setValorBase] = useState(0);
  const [valorAtualRenegociacao, setValorAtualRenegociacao] = useState(0);
  const [numeroParcelasRenegociacao, setNumeroParcelasRenegociacao] =
    useState(1);
  const [isModalPagamentoAberto, setIsModalPagamentoAberto] = useState(false);
  const [isModalHistoricoPagamentoAberto, setIsModalHistoricoPagamentoAberto] =
    useState(false);
  const [
    isModalHistoricoRenegociacaoAberto,
    setIsModalHistoricoRenegociacaoAberto,
  ] = useState(false);
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );
  const Swal = useSwalWrapper();

  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleCreateStatusOrcamentario = useCallback(async (descricao) => {
    const response = await ApiService.post("/statusorcamento", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getStatusOrcamento();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleOpenModalRenegociacao = () => {
    setIsModalRenegociacaoAberto(!isModalRenegociacaoAberto);
  };

  const handleOpenModalPagamento = () => {
    setIsModalPagamentoAberto(!isModalPagamentoAberto);
  };

  const handleOpenModalHistoricoPagamento = () => {
    setIsModalHistoricoPagamentoAberto(!isModalHistoricoPagamentoAberto);
  };

  const handleOpenModalHistoricoRenegociacao = () => {
    setIsModalHistoricoRenegociacaoAberto(!isModalHistoricoRenegociacaoAberto);
  };

  const handleAtualizarValorPago = (pagamento, acao) => {
    const { valor, id } = pagamento;

    if (acao === "remocao") {
      const novoPagamentosContrato = contrato.pagamentoscontrato.filter(
        (pag) => pag.id !== id
      );
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: novoPagamentosContrato,
      };
      setContrato(novoContrato);
      setValorPago((prev) => prev - valor);
    } else {
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: [...contrato.pagamentoscontrato, pagamento],
      };
      setContrato(novoContrato);
      setValorPago((prev) => prev + valor);
    }
  };

  const handleAtualizarValorBase = (valor) => {
    setValorBase(valor);
  };

  const handleAtualizarValorAtualComRenegociacao = (dados, acao) => {
    switch (acao) {
      case "adicao":
        setContrato((prevContrato) => ({
          ...prevContrato,
          renegociacoescontrato: [...prevContrato.renegociacoescontrato].concat(
            dados
          ),
        }));
        break;
      case "remocao":
        setContrato((prevContrato) => ({
          ...prevContrato,
          renegociacoescontrato: dados,
        }));
        break;
    }
    // if (novoValor !== undefined && parcelas !== undefined) {
    //   console.log("Atualizou renegociação")
    //   setValorAtualRenegociacao(novoValor);
    //   setNumeroParcelasRenegociacao(parcelas);
    // } else {
    //   console.log("Sem renegociação");
    //   setContrato((prevContrato) => ({
    //     ...prevContrato,
    //     renegociacoescontrato: [],
    //   }));
    // }
  };

  const handleAtualizarValorPagoAposEdicao = (idPagamento, novoValor) => {
    if (contrato && contrato.pagamentoscontrato) {
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: contrato.pagamentoscontrato.map((pagamento) =>
          pagamento.id === idPagamento
            ? { ...pagamento, valor: novoValor }
            : pagamento
        ),
      };
      setContrato(novoContrato);
    }
  };

  const handleAtualizarRenegociacaoAposEdicao = (
    idRenegociacao,
    valor,
    parcelas
  ) => {
    if (contrato && contrato.renegociacoescontrato) {
      const novoContrato = {
        ...contrato,
        renegociacoescontrato: contrato.renegociacoescontrato.map(
          (renegociacao) =>
            renegociacao.id === idRenegociacao
              ? {
                  ...renegociacao,
                  valor,
                  numeroParcelas: parcelas,
                }
              : renegociacao
        ),
      };
      setContrato(novoContrato);
    }
  };

  const handleAtualizarStatusOrcamentario = async (contratoId, statusId) => {
    try {
      await ApiService.put(
        `/contratos/status/${contratoId}/${statusId}/${userId}`
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Status Orçamentário atualizado!");
          getStatusOrcamento();
          getContrato();
        }
      });
    } catch (error) {
      console.log(error);
      toast("error", "Ocorreu um erro ao atualizar o Status Orçamentário");
    }
  };

  const getStatusOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        setStatusOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getContrato = async () => {
    setIsDadosCarregados(false);
    setContrato([]);
    try {
      const response = await ApiService.get(`/contratos/${contratoId}`);
      setContrato(response.data);
      setIsDadosCarregados(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAtualizarDataAprovacaoContrato = async (dataAprovacao) => {
    try {
      await ApiService.put(`/contratos/dataAprovacao/${contratoId}`, {
        dataAprovacao,
        statusContratoId: contrato?.status_orcamento_id,
      }).then((response) => {
        if (response.status === 200) {
          getContrato();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    const fetchData = async () => {
      await getContrato();
      await getStatusOrcamento();
      setIsDadosCarregados(true);
    };
    await fetchData();
  }, []);

  useEffect(() => {
    if (contrato && contrato.pagamentoscontrato) {
      const novoValorPago = contrato.pagamentoscontrato.reduce(
        (total, pagamento) => total + pagamento.valor,
        0
      );
      setValorPago(novoValorPago);
    } else {
      setValorPago(0);
    }
  }, [contrato]);

  useEffect(() => {
    if (contrato && contrato.servicos && contrato.servicos.length > 0) {
      let somaValorBase = 0;
      contrato.servicos.forEach((servico) => {
        somaValorBase += servico.valor * servico.quantidade;
        handleAtualizarValorBase(somaValorBase);
      });
    }
  }, [contrato]);

  useEffect(() => {
    if (
      contrato &&
      contrato.renegociacoescontrato &&
      contrato.renegociacoescontrato.length > 0
    ) {
      const valorRenegociacao =
        contrato.renegociacoescontrato[0].valor;
      setValorAtualRenegociacao(valorRenegociacao);
    }
  }, [contrato]);

  useEffect(() => {
    if (isDadosCarregados && contrato) {
      const temPermissaoDeAcessarAPagina = hasPermission("Financeiro", "read");
      if (!temPermissaoDeAcessarAPagina) {
        navigate("/app");
      }
    }
  }, [isDadosCarregados, contrato]);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          title={"Financeiro"}
          subtitle={"Contrato"}
          titleUrl={"/app/financeiro"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      {isDadosCarregados && (
        <>
          <ContratoHeader
            descricao={contrato.descricao}
            elaboradores={contrato.elaboradores_orcamento}
            dataCriacao={contrato.createdAt}
            dataAprovacao={contrato?.data_aprovacao}
            statusOrcamentos={statusOrcamento}
            statusOrcamentoId={contrato.status_orcamento_id}
            contratoId={contrato.id}
            cliente={contrato?.Cliente}
            handleAtualizarStatusOrcamentario={
              handleAtualizarStatusOrcamentario
            }
            handleAtualizarDataAprovacaoContrato={handleAtualizarDataAprovacaoContrato}
          />
          {contrato?.tipocontratocontrato?.some(
            (tipoContrato) =>
              String(tipoContrato?.tipos_contratos?.descricao).toLowerCase() ===
              "contrato fixo"
          ) || (
            <Paper elevation={2} style={{ padding: 10, marginTop: 20 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={6}
                  display={"flex"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Box width={"50%"}>
                    <Chip
                      color="warning"
                      icon={
                        <Paid fontSize="large" style={{ color: "white" }} />
                      }
                      style={{
                        width: "100%",
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        padding: 10,
                      }}
                      label={
                        <Typography fontSize={"14px"}>
                          Valor atual:{" "}
                          {contrato?.renegociacoescontrato?.length > 0 ? (
                            <>
                              {valorAtualRenegociacao.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}{" "}
                              em{" "}
                              {`${
                                contrato.renegociacoescontrato[
                                  contrato.renegociacoescontrato.length - 1
                                ].numeroParcelas
                              }x`}
                            </>
                          ) : (
                            <>
                              {valorBase.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </>
                          )}
                        </Typography>
                      }
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Stack spacing={1} direction="row">
                      <Button
                        size="medium"
                        variant="contained"
                        color="success"
                        startIcon={<PaidOutlined />}
                        onClick={handleOpenModalRenegociacao}
                      >
                        Renegociar
                      </Button>
                      <Button
                        size="medium"
                        variant="outlined"
                        color="info"
                        startIcon={<Topic />}
                        onClick={handleOpenModalHistoricoRenegociacao}
                      >
                        Histórico
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item lg={6} display={"flex"} gap={1}>
                  <Box width={"50%"}>
                    <Chip
                      color="warning"
                      icon={
                        <Paid fontSize="large" style={{ color: "white" }} />
                      }
                      style={{
                        width: "100%",
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        padding: 10,
                      }}
                      label={
                        <Typography fontSize={"14px"}>
                          Valor pago:{" "}
                          {valorPago.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </Typography>
                      }
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Stack spacing={2} direction="row">
                      <Button
                        size="medium"
                        variant="contained"
                        color="success"
                        startIcon={<AddIcon />}
                        onClick={handleOpenModalPagamento}
                      >
                        Pagamento
                      </Button>
                      <Button
                        size="medium"
                        variant="outlined"
                        color="info"
                        startIcon={<Topic />}
                        onClick={handleOpenModalHistoricoPagamento}
                      >
                        Histórico
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <ModalRenegociarContrato
                aberto={isModalRenegociacaoAberto}
                handleClose={handleOpenModalRenegociacao}
                contratoId={contrato.id}
                atualizarValorRenegociacao={
                  handleAtualizarValorAtualComRenegociacao
                }
                valorAtualContrato={valorBase}
              />
              <ModalHistoricoRenegociacaoContrato
                aberto={isModalHistoricoRenegociacaoAberto}
                handleClose={handleOpenModalHistoricoRenegociacao}
                contratoId={contratoId}
                atualizarValorRenegociacao={
                  handleAtualizarValorAtualComRenegociacao
                }
                valorBase={valorBase}
                atualizarValorAposEdicao={handleAtualizarRenegociacaoAposEdicao}
              />
              <ModalPagamentoContrato
                aberto={isModalPagamentoAberto}
                handleClose={handleOpenModalPagamento}
                contratoId={contrato.id}
                atualizarValorPago={handleAtualizarValorPago}
              />
              <ModalHistoricoPagamentoContrato
                aberto={isModalHistoricoPagamentoAberto}
                handleClose={handleOpenModalHistoricoPagamento}
                contratoId={contrato.id}
                atualizarValorPago={handleAtualizarValorPago}
                atualizarValorAposEdicao={handleAtualizarValorPagoAposEdicao}
                isReadOnly={false}
              />
            </Paper>
          )}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Paper sx={{ p: 2, my: 2 }}>
                  <Card sx={{ mb: 1, p: 2 }} style={{ width: "100%" }}>
                    {contrato && contrato.servicos && (
                      <div>
                        {Object.entries(
                          contrato.servicos.reduce((acc, servico) => {
                            if (servico.processo_administrativo !== null) {
                              if (!acc[servico.processo_administrativo?.id]) {
                                acc[servico.processo_administrativo?.id] = {
                                  processo_administrativo:
                                    servico.processo_administrativo,
                                  servicos: [],
                                };
                              }
                              acc[
                                servico.processo_administrativo?.id
                              ].servicos.push(servico);
                            }
                            return acc;
                          }, {})
                        ).map(
                          ([
                            processoAdministrativoId,
                            { processo_administrativo, servicos },
                          ]) => (
                            <Accordion
                              key={processoAdministrativoId}
                              sx={{ my: 2 }}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Stack
                                  width="100%"
                                  direction="row"
                                  justifyContent={"space-between"}
                                  p={2}
                                >
                                  <Box>
                                    <Typography variant="h3">
                                      Processo Administrativo:{" "}
                                      {processo_administrativo?.descricao ??
                                        "N/I"}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Stack direction="column" gap={1}>
                                      <Typography fontWeight={600}>
                                        Situação:
                                      </Typography>
                                      <Typography>
                                        {
                                          processo_administrativo?.status[0]
                                            .status_processo?.descricao
                                        }
                                      </Typography>
                                    </Stack>
                                  </Box>
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails sx={{ overflowY: "auto" }}>
                                <div>
                                  {servicos
                                    .filter(
                                      (servico) =>
                                        servico.tarefas_servico.length > 0
                                    )
                                    .map((servico, index) => (
                                      <div key={index}>
                                        <Grid container spacing={2} key={index}>
                                          <Grid item xs={8}>
                                            <List>
                                              <ListItem>
                                                <ListItemIcon>
                                                  <DescriptionIcon />
                                                </ListItemIcon>
                                                <ListItemText>
                                                  <Typography variant="h4">
                                                    {
                                                      servico.tarefas_servico[0]
                                                        ?.descricao
                                                    }
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            </List>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <FormControl
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <Typography
                                                my={2}
                                                fontWeight={600}
                                              >
                                                Status do Serviço
                                              </Typography>
                                              <Box>
                                                <Typography>
                                                  {
                                                    servico
                                                      .status_andamento_servico
                                                      .descricao
                                                  }
                                                </Typography>
                                              </Box>
                                            </FormControl>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    ))}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )
                        )}
                      </div>
                    )}
                  </Card>
                </Paper>
              </Form>
            )}
          </Formik>
        </>
      )}
    </JumboContentLayout>
  );
};
