import React, { useState, useCallback, useEffect, useContext } from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Select,
  Avatar,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ApiService } from "app/services/config";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue } from "../../utils/appHelpers";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import makeAnimated from "react-select/animated";
import ReactInputMask from "react-input-mask";
import { Delete, PhotoCamera } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { PermissionContext } from "app/contexts/PermissionContext";
import { users } from "app/shared/widgets/ProjectsListCard/data";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  name: yup
    .string("Insira o nome da área")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Insira um email válido")
    .email("Email inválido")
    .required("Preenchimento obrigatório"),
  telephone: yup
    .string("Insira um número de telefone válido")
    .matches(/^\(\d{2}\) 9\d{4}-\d{4}$/, "Insira um número de celular válido")
    .nullable(),
  password: yup.string().required("Preenchimento obrigatório"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
    .required("Preenchimento obrigatório"),
  avatarUrl: yup.string().nullable(),
});

const validationSchemaEdit = yup.object().shape({
  name: yup
    .string("Insira o nome da área")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Insira um email válido")
    .email("Email inválido")
    .required("Preenchimento obrigatório"),
  telephone: yup
    .string("Insira um número de telefone válido")
    .matches(/^\(\d{2}\) 9\d{4}-\d{4}$/, "Insira um número de celular válido")
    .nullable(),
  avatarUrl: yup.string().nullable(),
  password: yup.string().nullable(),
  passwordConfirm: yup.string().when("password", {
    is: (password) => !!password,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
      .required("Preenchimento obrigatório quando a senha é fornecida"),
    otherwise: yup.string().nullable(),
  }),
});

const UserForm = () => {
  const initialValues = {
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",
    telephone: "",
    userType: "",
  };
  const { id } = useParams();
  const USER_ID = Number(localStorage.getItem("id"));
  const ROLE_ID = Number(localStorage.getItem("role_id"));
  const [user, setUser] = useState(initialValues);
  const [previewFotoPerfil, setPreviewFotoPerfil] = useState(null);
  const [erroUploadFotoPerfil, setErroUploadFotoPerfil] = useState({
    status: false,
    mensagem: "",
  });
  const [roles, setRoles] = useState([]);
  const [hideRole, setHideRole] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState(null);
  const [telefoneComMascara, setTelefoneComMascara] = useState("");
  const [
    isUsuarioComPermissaoParaEditarSenha,
    setIsUsuarioComPermissaoParaEditarSenha,
  ] = useState(false);
  const animatedComponents = makeAnimated();
  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const { theme } = useJumboTheme();

  const { hasPermission } = useContext(PermissionContext);

  const isUsuarioDonoDoPerfil = Number(id) === USER_ID;

  if (id) {
    if (!hasPermission("Colaboradores", "update") && Number(id) !== USER_ID) {
      navigate("/app/listar-colaboradores");
    }
  } else {
    if (!hasPermission("Colaboradores", "create")) {
      navigate("/app/listar-colaboradores");
    }
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getUsers = useCallback(async () => {
    try {
      await ApiService.get(`/users/${id}`)
        .then((response) => {
          const transformedUser = {
            ...response.data,
            password: undefined,
          };

          setUser(transformedUser);
          setTelefoneComMascara(response.data.telephone);
          if (response.data.avatarUrl) {
            setPreviewFotoPerfil(
              `${process.env.REACT_APP_API_KEY}/public/uploads/${response.data.avatarUrl}`
            );
          }

          setActivitiesSelected(response.data.roles[0].role_id);

          const currentRoleId = parseInt(localStorage.getItem("role_id"), 10);

          const rolesFinanceiroCanEdit = [6, 7];
          const rolesAdminCanEdit = [6];
          console.log(`Current role: ${currentRoleId}`);
          if (currentRoleId === 5 || currentRoleId === 7) {
            setHideRole(
              currentRoleId == 5 ? rolesFinanceiroCanEdit : rolesAdminCanEdit
            );
          } 
          if (currentRoleId === 6) {
            console.log('é colaborador')
            setHideRole([1, 2, 3, 4, 5, 7]);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [roles]);

  const getRoles = useCallback(async () => {
    try {
      await ApiService.get(`/roles`)
        .then((response) => {
          const roles = response.data;

          const currentRoleId = parseInt(localStorage.getItem("role_id"), 10);
          const currentUserRole = roles.find(
            (role) => role.id === currentRoleId
          );
          const currentUserRoleName = currentUserRole
            ? currentUserRole.name
            : null;

          const filteredRoles = roles.filter((role) => {
            if (currentUserRoleName === "Financeiro") {
              return ["Financeiro", "Administrador", "Colaborador"].includes(
                role.name
              );
            } else if (currentUserRoleName === "Administrador") {
              return ["Administrador", "Colaborador"].includes(role.name);
            }
            return true;
          });

          setRoles(filteredRoles);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const optionsRoles = roles?.map((result) => {
    return {
      value: result.id,
      label: result.name,
    };
  });

  const modalAlert = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleRemoveFotoPerfil();
      }
    });
  };

  const handlePreviewFotoPerfil = async (event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      if (
        selectedFile.type !== "image/jpeg" &&
        selectedFile.type !== "image/png"
      ) {
        setPreviewFotoPerfil(null);
        setErroUploadFotoPerfil({
          status: true,
          mensagem: "Formato de imagem inválido. Só é permitido .jpeg ou .png",
        });
        console.log("Tipo de imagem inválida", selectedFile.type);
        return;
      } else {
        setErroUploadFotoPerfil({
          status: false,
          mensagem: "",
        });

        reader.onloadend = () => {
          setPreviewFotoPerfil(reader.result);
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const handleUploadImagemPerfil = async (event) => {
    const fotoPerfil = event.target.files[0];
    const formData = new FormData();
    formData.append("foto", fotoPerfil);
    try {
      await ApiService.put(`users/upload/foto/perfil/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto de perfil atualizada!");
          setUser((prevUser) => ({
            ...prevUser,
            avatarUrl: response.data.avatarUrl,
          }));
          localStorage.setItem("avatarUrl", response.data.avatarUrl);
        }
      });
    } catch (error) {
      console.error("Erro ao fazer upload da imagem: ", error);
    }
  };

  const handleRemoveFotoPerfil = async () => {
    setPreviewFotoPerfil(null);
    setErroUploadFotoPerfil({
      status: false,
      mensagem: "",
    });
    try {
      await ApiService.put(`/users/foto/remover/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto removida com sucesso!");
          setPreviewFotoPerfil(null);
          setUser((prevUser) => ({
            ...prevUser,
            avatarUrl: null,
          }));
        }
      });
    } catch (error) {
      console.log(error);
      toast("error", "Erro ao remover a imagem de perfil");
    }
  };

  useEffect(() => {
    getRoles();
  }, []); // Chamado apenas uma vez quando o componente for montado

  useEffect(() => {
    if (roles.length > 0 && id) {
      // Verifica se roles foi atualizado
      getUsers();
    }
  }, [roles, id]);

  const usuariosQueFinanceiroPodeEditarSenha = [6, 7];
  const usuariosQueAdministradorPodeEditarSenha = [6];

  useEffect(() => {
    if (user && user?.roles?.length > 0) {
      if (isUsuarioDonoDoPerfil || ROLE_ID === 1) {
        setIsUsuarioComPermissaoParaEditarSenha(true);
      } else if (
        isUsuarioDonoDoPerfil ||
        (ROLE_ID === 5 &&
          usuariosQueFinanceiroPodeEditarSenha.includes(
            user?.roles[0]?.role_id
          ))
      ) {
        setIsUsuarioComPermissaoParaEditarSenha(true);
      } else if (
        isUsuarioDonoDoPerfil ||
        (ROLE_ID === 7 &&
          usuariosQueAdministradorPodeEditarSenha.includes(
            user?.roles[0]?.role_id
          ))
      ) {
        setIsUsuarioComPermissaoParaEditarSenha(true);
      }
    }
  }, [user]);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const allValuesEdit = { ...values, userType: activitiesSelected };
    delete allValuesEdit.servicoId;
    delete allValuesEdit.dados_cliente;
    if (id) {
      ApiService.put(`/users/${id}`, { values: allValuesEdit })
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso");
            resetForm();
            navigate("/app/listar-colaboradores");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/users", { values: allValuesEdit })
        .then((response) => {
          if (response.status === 201) {
            toast("success", "Criado com sucesso");
            resetForm();
            navigate("/app/listar-colaboradores");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    setErroUploadFotoPerfil(erroUploadFotoPerfil);
  }, [erroUploadFotoPerfil]);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Colaboradores"}
          subtitle={id ? "Editar" : "Cadastro de Colaboradores"}
          titleUrl={"/app/listar-colaboradores"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: "15px" }}>
        <Formik
          initialValues={user}
          validationSchema={id ? validationSchemaEdit : validationSchema}
          enableReinitialize
          validateOnChange
          validateOnBlur
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid
                container
                spacing={2}
                padding={2}
                alignContent={"center"}
                justifyContent={"center"}
              >
                {id && (
                  <Grid item lg={4} alignContent={"center"}>
                    <form>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                      >
                        <Typography fontSize={"16px"} style={{ margin: 2 }}>
                          Foto de perfil
                        </Typography>
                        <Avatar
                          alt="Foto do perfil"
                          src={
                            user.avatarUrl
                              ? `${process.env.REACT_APP_API_KEY}/public/uploads/${user.avatarUrl}`
                              : previewFotoPerfil
                          }
                          sx={{
                            width: 140,
                            height: 140,
                            marginTop: 2,
                            bgcolor: "success",
                          }}
                        >
                          {user.name ? user.name.charAt(0).toUpperCase() : "P"}
                        </Avatar>
                      </Box>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        marginTop={2}
                        justifyContent={"center"}
                        gap={1}
                      >
                        <form></form>
                        <IconButton
                          color="info"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            name="foto"
                            hidden
                            accept="image/png, image/jpg, image/jpeg"
                            type="file"
                            onChange={(event) => {
                              handlePreviewFotoPerfil(event);
                              handleUploadImagemPerfil(event);
                            }}
                          />
                          <PhotoCamera />
                        </IconButton>
                        {previewFotoPerfil && (
                          <>
                            <IconButton onClick={modalAlert}>
                              <Delete color="error" />
                            </IconButton>
                          </>
                        )}
                      </Box>
                      {erroUploadFotoPerfil.status && (
                        <Box sx={{ textAlign: "center" }}>
                          <Typography color="error">
                            {erroUploadFotoPerfil.mensagem}
                          </Typography>
                        </Box>
                      )}
                    </form>
                  </Grid>
                )}

                <Grid item lg={8} marginTop={5}>
                  <Grid container alignContent={"center"} spacing={2}>
                    <Grid item lg={5} xs={12}>
                      <JumboTextField
                        fullWidth
                        name="name"
                        label="Nome"
                        required
                        value={updateInputValue(user, values, "name")}
                        sx={{ background: "#ffffff" }}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <JumboTextField
                        fullWidth
                        name="email"
                        label="Email"
                        required
                        value={updateInputValue(user, values, "email")}
                        sx={{ background: "#ffffff" }}
                      />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <ReactInputMask
                        mask="(99) 99999-9999"
                        maskChar=""
                        name="telephone"
                        value={telefoneComMascara}
                        onChange={(event) => {
                          const numeroTelefone = event.target.value;
                          setTelefoneComMascara(numeroTelefone);
                          setFieldValue("telephone", numeroTelefone);
                        }}
                      >
                        {(inputProps) => (
                          <TextField
                            fullWidth
                            name="telephone"
                            label="Telefone"
                            sx={{ background: "#ffffff" }}
                            {...inputProps}
                          />
                        )}
                      </ReactInputMask>
                      <ErrorMessage
                        component={"div"}
                        style={{ color: "red" }}
                        name="telephone"
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container marginTop={2} spacing={2}>
                      {(isUsuarioComPermissaoParaEditarSenha || !id) && (
                        <>
                          <Grid item lg={3} xs={12}>
                            <JumboTextField
                              fullWidth
                              name="password"
                              type="password"
                              label="Senha"
                              required
                              value={updateInputValue(user, values, "password")}
                              sx={{ background: "#ffffff" }}
                            />
                          </Grid>
                          <Grid item lg={3} xs={12}>
                            <JumboTextField
                              fullWidth
                              type="password"
                              name="passwordConfirm"
                              label="Confirmação de senha"
                              required
                              value={updateInputValue(
                                user,
                                values,
                                "passwordConfirm"
                              )}
                              sx={{ background: "#ffffff" }}
                            />
                          </Grid>
                        </>
                      )}

                      {(hideRole.length == 0 ||
                        hideRole.includes(activitiesSelected)) && (
                        <Grid item lg={6} xs={12}>
                          <div style={{ position: "relative", marginTop: -20 }}>
                            <InputLabel id="role-usuario">
                              Tipo de usuário
                            </InputLabel>
                            <Select
                              labelId="role-usuario"
                              value={
                                activitiesSelected
                                  ? activitiesSelected
                                  : "cliente"
                              }
                              onChange={(event) => {
                                setActivitiesSelected(event.target.value);
                              }}
                              defaultValue={
                                user.userType ? user.userType : roles[0]
                              }
                              fullWidth
                            >
                              {roles.map((role, index) => (
                                <MenuItem key={index} value={role.id}>
                                  {role.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                my={2}
              >
                <LoadingButton
                  color="success"
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    display: "auto",
                    maxWidth: {
                      md: "200px",
                      mt: "1rem",
                    },
                  }}
                  loading={isSubmitting}
                >
                  {id ? "Atualizar" : "Cadastrar"}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default UserForm;
