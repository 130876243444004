import React, { useState, useCallback, useEffect, useContext } from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ApiService } from "app/services/config";
import { Link, useLocation } from "react-router-dom";
import UserItem from "./UserItem";
import MUIDataTable from "mui-datatables";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "app/contexts/PermissionContext";

const UserList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isTodosUsuariosSelecionados, setIsTodosUsuariosSelecionados] =
    useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const navigate = useNavigate();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const ROLE_ID = Number(localStorage.getItem("role_id"));
  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Colaboradores", "read")) {
    navigate("/app");
  }
  const permission = hasPermission("Colaboradores", "create");
  const permissionUpdate = hasPermission("Colaboradores", "update");
  const permissionDelete = hasPermission("Colaboradores", "delete");
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const usuariosQuePodemSerSelecionadosPorFinanceiro = [6, 7];
  const usuariosQuePodemSerSelecionadosPorAdministrador = [6];
  const usuariosQuePodemSerSelecionadosPorColaborador = [];
  const isUsuarioFinanceiro = ROLE_ID === 5;
  const isUsuarioAdministrador = ROLE_ID === 7;
  const isUsuarioColaborador = ROLE_ID === 6;

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteUser(id);
      }
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const deleteUser = useCallback(async (id) => {
    try {
      ApiService.delete(`/users/${id}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso");
            setPerPage(10);
            setPage(0);
            setForceUpdate((prev) => !prev);
            getUsers();
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getUsers = useCallback(async () => {
    try {
      let apiUrl = `/users?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.users.map((user) => ({
            ...user,
            isSelected: selectedUserIds.includes(user.id),
          }));
          setUsers(initialData);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedUserIds]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleActivate = (userId) => {
    try {
      ApiService.post("/users/active", { values: userId }).then(() => {
        toast("success", "Ativado com sucesso");
        getUsers();
      });
    } catch (error) {
      toast("error", error.response.data.message);
    }
  };

  const handleInactivate = (userId) => {
    try {
      ApiService.post("/users/inactive", { values: userId }).then(() => {
        toast("success", "Inativado com sucesso");
        getUsers();
      });
    } catch (error) {
      toast("error", error.response.data.message);
    }
  };

  /**
   * Esta função é chamada quando o checkbox associado a um usuário específico é alterado.
   * 1. Atualiza o estado dos usuários:
   *    - Para o usuário com o ID correspondente, inverte o valor da propriedade 'isSelected' (de true para false ou vice-versa).
   * 2. Gerencia os IDs dos usuários selecionados:
   *    - Se o ID do usuário já estiver na lista de IDs selecionados, ele é removido da lista.
   *    - Caso contrário, o ID do usuário é adicionado à lista de IDs selecionados.
   * Dessa forma, esta função garante que, ao marcar ou desmarcar um usuário, tanto a visualização da seleção na UI quanto a lista interna de IDs selecionados sejam atualizadas corretamente.
   */
  const handleCheckboxChange = (userId) => {
    const userChecked = users.find((user) => user.id === userId);
    const userCheckedRoleId = userChecked?.roles[0]?.Role?.id;

    if (
      ROLE_ID === 1 ||
      (isUsuarioAdministrador &&
        usuariosQuePodemSerSelecionadosPorAdministrador.includes(
          userCheckedRoleId
        )) ||
      (isUsuarioFinanceiro &&
        usuariosQuePodemSerSelecionadosPorFinanceiro.includes(
          userCheckedRoleId
        )) ||
      (isUsuarioColaborador &&
        usuariosQuePodemSerSelecionadosPorColaborador.includes(
          userCheckedRoleId
        ))
    ) {
      const updatedUsers = users.map((user) =>
        user.id === userId ? { ...user, isSelected: !user.isSelected } : user
      );
      setUsers(updatedUsers);

      const selectedUsers = updatedUsers.filter((user) => user.isSelected);
      setSelectedUserIds(selectedUsers.map((user) => user.id));

      setMostrarBotaoDeletarMuitos(selectedUsers.length > 0);
      setIsTodosUsuariosSelecionados(
        selectedUsers.length === users.filter((user) => user.isSelected).length
      );
    }
  };

  const handleDeleteMuitosUsuarios = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os usuários selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosUsuarios();
      }
    });
  };

  const deleteMuitosUsuarios = useCallback(async () => {
    try {
      await ApiService.put("/users/delete/many/users", selectedUserIds).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Usuários removidos com sucesso!");
            getUsers();
            setSelectedUserIds([]);
            setPage(0);
            setMostrarBotaoDeletarMuitos(false);
          }
        }
      );
    } catch (error) {
      toast("error", "Não foi possível deletar os usuários selecionados");
      console.log(error);
    }
  }, [selectedUserIds]);

  const handleSelecionarTodosUsuarios = () => {
    const allSelected = !isTodosUsuariosSelecionados;

    let usuariosFiltrados = users;
    if (ROLE_ID !== 1) {
      switch (ROLE_ID) {
        case 5:
          usuariosFiltrados = users?.filter((usuario) =>
            usuariosQuePodemSerSelecionadosPorFinanceiro.includes(
              usuario?.roles[0]?.Role?.id
            )
          );
          break;
        case 7:
          usuariosFiltrados = users?.filter((usuario) =>
            usuariosQuePodemSerSelecionadosPorAdministrador.includes(
              usuario?.roles[0]?.Role?.id
            )
          );
          break;
        default:
          usuariosFiltrados = [];
      }
    }

    const updatedUsers = users.map((user) => ({
      ...user,
      isSelected:
        ROLE_ID === 1
          ? allSelected
          : usuariosFiltrados.some(
              (filteredUser) => filteredUser.id === user.id
            ),
    }));

    setUsers(updatedUsers);

    const todosIdsUsers = allSelected
      ? usuariosFiltrados.map((user) => user.id)
      : [];
    setSelectedUserIds(todosIdsUsers);
    setMostrarBotaoDeletarMuitos(todosIdsUsers.length > 0);
    setIsTodosUsuariosSelecionados(allSelected);
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const userId = tableMeta.rowData[1];
          const user = users.find((u) => u.id === userId);
          return (
            <Checkbox
              checked={user.isSelected}
              onChange={() => handleCheckboxChange(userId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "name",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "roles",
      label: "Tipo de usuário",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return value.map((role) => role.Role.name).join(", ");
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let badgeColor;
          let statusText;

          if (value === "ativo" || value === true) {
            badgeColor = "success";
            statusText = "Ativo";
          } else {
            badgeColor = "error";
            statusText = "Inativo";
          }

          return <Chip label={statusText} color={badgeColor} />;
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const userId = tableMeta.rowData[1];
          const status = tableMeta.rowData[5];
          const roleId = tableMeta.rowData[7][0]?.Role?.id;
          return (
            <Box display={"flex"} gap={"1rem"}>
              {status === "inativo" ? (
                <Tooltip title="Ativar usuário">
                  <Button
                    color="info"
                    variant="contained"
                    size="small"
                    disableElevation
                    disabled={
                      (ROLE_ID !== 1 || ROLE_ID === 6) &&
                      ((isUsuarioFinanceiro &&
                        !usuariosQuePodemSerSelecionadosPorFinanceiro.includes(
                          roleId
                        )) ||
                        (isUsuarioAdministrador &&
                          !usuariosQuePodemSerSelecionadosPorAdministrador.includes(
                            roleId
                          )) ||
                        (isUsuarioColaborador &&
                          !usuariosQuePodemSerSelecionadosPorColaborador.includes(
                            roleId
                          )))
                    }
                    onClick={() => handleActivate([userId])}
                  >
                    <CheckIcon />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Desativar usuário">
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    disableElevation
                    disabled={
                      (ROLE_ID !== 1 || ROLE_ID === 6) &&
                      ((isUsuarioFinanceiro &&
                        !usuariosQuePodemSerSelecionadosPorFinanceiro.includes(
                          roleId
                        )) ||
                        (isUsuarioAdministrador &&
                          !usuariosQuePodemSerSelecionadosPorAdministrador.includes(
                            roleId
                          )) ||
                        (isUsuarioColaborador &&
                          !usuariosQuePodemSerSelecionadosPorColaborador.includes(
                            roleId
                          )))
                    }
                    onClick={() => handleInactivate([userId])}
                  >
                    <BlockIcon />
                  </Button>
                </Tooltip>
              )}
              <Button
                color="info"
                variant="contained"
                size="small"
                disableElevation
                disabled={!permissionUpdate}
                onClick={() => {
                  navigate(`/app/editar-colaborador/${userId}`);
                }}
              >
                <VisibilityIcon />
              </Button>
              <Button
                color="error"
                variant="contained"
                size="small"
                disabled={
                  (ROLE_ID !== 1 || ROLE_ID === 6) &&
                  ((isUsuarioFinanceiro &&
                    !usuariosQuePodemSerSelecionadosPorFinanceiro.includes(
                      roleId
                    )) ||
                    (isUsuarioAdministrador &&
                      !usuariosQuePodemSerSelecionadosPorAdministrador.includes(
                        roleId
                      )) ||
                    (isUsuarioColaborador &&
                      !usuariosQuePodemSerSelecionadosPorColaborador.includes(
                        roleId
                      )))
                }
                onClick={() => modalAlert(userId)} // Função para deletar usuário
              >
                <DeleteIcon />
              </Button>
            </Box>
          );
        },
      },
    },
    {
      name: "roles",
      label: "ID do Tipo de Perfil",
      options: {
        display: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return value[0]?.Role?.id;
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedUserIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <Tooltip title="Ativar">
                <Button
                  color="info"
                  variant="contained"
                  size="small"
                  disableElevation
                  disabled={!permission}
                  onClick={() => handleActivate(selectedUserIds)}
                >
                  <CheckIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Inativar">
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  disableElevation
                  disabled={!permission}
                  onClick={() => handleInactivate(selectedUserIds)}
                >
                  <BlockIcon />
                </Button>
              </Tooltip>
            </Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={"Colaboradores"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Grid
        container
        spacing={2}
        my={1}
        justifyContent="flex-end"
        alignItems="center"
      >
        {mostrarBotaoDeletarMuitos && (
          <Grid item>
            <span style={{ margin: 10 }}>
              {selectedUserIds.length} usuário (s) selecionado(s)
            </span>
            <Button
              color="error"
              variant="contained"
              onClick={handleDeleteMuitosUsuarios}
            >
              <DeleteIcon />
              Deletar Usuários Selecionados
            </Button>
          </Grid>
        )}

        {ROLE_ID !== 6 ? (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSelecionarTodosUsuarios}
            >
              {isTodosUsuariosSelecionados
                ? "Desmarcar vários"
                : "Selecionar vários"}
            </Button>
          </Grid>
        ) : null}
      </Grid>

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <LoadingButton
          color="success"
          type="submit"
          variant="contained"
          size="large"
          sx={{
            maxWidth: { md: "450px", mt: "1rem" },
            marginBottom: "2rem",
          }}
          disabled={!permission}
          onClick={() => {
            navigate(`/app/novo-colaborador`);
          }}
        >
          Novo Colaborador
        </LoadingButton>
      </Grid>

      <React.Fragment>
        <MUIDataTable
          key={forceUpdate ? "force-update" : "normal"}
          title={"Colaboradores"}
          data={users}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default UserList;
